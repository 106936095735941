// ADMIN HISTORY

export const GET_ADMIN_HISTORY = "GET_ADMIN_HISTORY";

export const GET_ADMIN_EARNING = "GET_ADMIN_EARNING";


// USER HISTORY
export const GET_ADMIN_CASHOUT = "GET_ADMIN_CASHOUT";



export const GET_HOST_HISTORY = "GET_HOST_HISTORY"
export const GET_HOST_INCOME = "GET_HOST_INCOME"
export const GET_HOST_LIVE_HISTORY = "GET_HOST_LIVE_HISTORY"

export const BIND_ACCOUNT_SUCCESS = "BIND_ACCOUNT_SUCCESS"

